import { service_url } from "@/api/config";
import Http from "../api/http";

export function login(data) {
  return Http._axios({
    url: `${service_url}/api/v1/auth/login/`,
    method: "post",
    data: { ...data },
  });
}

export function logout() {
  return Http._axios({
    url: `${service_url}/api/v1/auth/logout/`,
    method: "post",
  });
}

export function logoutAll() {
  return Http._axios({
    url: `${service_url}/api/v1/auth/logout/all/`,
    method: "post",
  });
}

export function getProfile() {
  return Http._axios({
    url: `${service_url}/api/v1/profile/`,
    method: "get",
  });
}
