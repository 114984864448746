import axios from "axios";
import { requestStatus } from "../services";
// import {url} from '../services/config';

class Http {
  static _axios(config) {
    if (!config || !config.url) {
      throw Object.assign({
        message: "Http request error: config required",
      });
    }

    config.method = config.method || "get";

    if (
      config.url &&
      config.url.indexOf("http") === -1 &&
      config.url.indexOf("https") === -1 &&
      config.url[0] !== "/"
    )
      config.url = "/" + config.url;

    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      axios.defaults.headers.common["Authorization"] = `Token ${user.token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    return axios(config)
      .then((response) => {
        if (response.status === 204) {
          return {
            response: response,
            status: requestStatus.success,
            codeStatus: response.status,
          };
        } else {
          return {
            response: response,
            status: requestStatus.success,
          };
        }
      })
      .catch((error) => {
        // if(error.response && error.response.status && (+error.response.status === 401  || +error.response.status === 403)){ //unauthorization
        //     if(user && user.token) {
        //         return {response: {}, status: requestStatus.wait_logout};
        //     }
        //     else {
        //         localStorage.removeItem('user');
        //         window.location = `${url}/login`;
        //
        //         return {response: {}, status: requestStatus.logout};
        //     }
        // }

        if (
          error.response &&
          error.response.status &&
          +error.response.status === 401
        ) {
          //unauthorization
          localStorage.removeItem("user");

          return { response: {}, status: requestStatus.logout };
        }
        if (!error.response) {
          return {
            status: requestStatus.error_connection,
            messages: "Server Error. Try again later.",
          };
        }
        let response_error = {
          status: requestStatus.error,
          code: +error.response.status,
        };
        if (error.response && error.response.data && error.response.status) {
          switch (+error.response.status) {
            case 405:
            case 400:
              response_error = {
                status: requestStatus.error,
                messages: buildErrorMessages(error.response.data),
              };
              break;
            case 403:
              response_error = {
                status: requestStatus.error,
                messages: buildErrorMessages(
                  error.response.data || "FORBIDDEN"
                ),
              };
              break;
            case 500:
              response_error = {
                status: requestStatus.error,
                messages: "Server Error. Try again later.",
              };
              break;
            default:
              response_error = { status: requestStatus.error };
          }
        }
        return response_error;
      });
  }
}

let buildThreeErrorMessages = (data) => {
  let error_msg = "";
  for (let key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      let key_print = `${key}` === "0" ? "" : `${key.replace(/_/g, " ")}: `;
      if (typeof data[key] === "object") {
        error_msg += `${key_print}${buildThreeErrorMessages(data[key])}\n`;
      } else {
        error_msg += `${key_print}${data[key]}\n`;
      }
    }
  }
  return error_msg;
};

let buildErrorMessages = (data) => {
  if (data.detail) {
    return data.detail;
  } else {
    if (data.non_field_errors) {
      return data.non_field_errors[0];
    }
    if (data.error) {
      return data.error;
    } else {
      // return JSON.stringify(data);

      // for (let key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     error_msg += `${key.replace(/_/g, " ")}: ${data[key]}\n`
      //   }
      // }
      return buildThreeErrorMessages(data);
    }
  }
};

export default Http;
