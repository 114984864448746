import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView"),
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MainView"),
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Dashboard/DashboardView"
          ),
      },
      {
        path: "/offer/:id",
        name: "offer",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Offer/OfferPage"
          ),
      },
      {
        path: "/stats",
        name: "Stats",
        redirect: "/stats/date",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/StatsWrp"),
        children: [
          {
            path: "/stats/date",
            name: "datastat",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/stats/dateStat/DateStat"
              ),
          },
          {
            path: "/stats/offers",
            name: "offers",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/stats/offersStat/OffersStat"
              ),
          },
          {
            path: "/stats/country",
            name: "country",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/stats/countryStat/CountryStat"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PageNotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (!user && to.path !== "/login") {
    next("/login");
    return;
  }

  if (to.path === "/login" && user) {
    next("/");
    return;
  }

  next();
});

export default router;
