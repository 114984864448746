<template>
  <div>
    <v-dialog
      persistent
      content-class="confirm-wrp"
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <div class="confirm-block">
        <div class="confirm-block__header">
          {{ title }}
        </div>
        <div class="confirm-block__body">
          <v-btn small @click="cancel"> Cancel </v-btn>
          <v-btn small color="info" @click="agree"> Save </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    agreeTitle: "Yes",
    cancelTitle: "No",
    title: null,
    options: {
      width: 350,
      zIndex: 200,
    },
  }),
  methods: {
    open(title, buttons, options) {
      this.dialog = true;
      this.title = title;
      if (buttons) {
        this.agreeTitle = buttons.agreeTitle;
        this.cancelTitle = buttons.cancelTitle;
      }
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.confirm-wrp {
  padding: 0 !important;
}
.confirm-block {
  background: #ffffff;
  padding: 20px;
  &__header {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 26px;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    .confirm-success {
      background-color: #a3cc0e;
      color: #ffffff;
    }
    .confirm-error {
      background-color: #ff5252;
      color: #ffffff;
    }
    button {
      min-width: 100px;
      padding: 6px;
    }
  }
}
@media (max-width: 768px) {
  .confirm-wrp.v-dialog {
    height: auto;
    width: 90%;
    max-width: 90%;
    margin: 5% 5% 0 5%;
    .confirm-block {
      padding: 20px;
    }
  }
}
</style>
