export const api_prefix = "api/v1";

export const TRAFFIC_SOURCE = [
  {
    label: "Facebook",
    value: "fb",
  },
  {
    label: "TikTok",
    value: "tt",
  },
  {
    label: "Unknown",
    value: "N/A",
  },
];

export const requestStatus = {
  loading: "loading",
  success: "success",
  error: "error",
  error_connection: "error_connection",
  logout: "logout",
};

export function buildFilterString(query) {
  //, omit=[]) {
  let result = "";
  for (let key in query) {
    // if (key in omit) {
    result +=
      query[key] !== null && query[key] !== undefined && query[key] !== ""
        ? `&${key}=${query[key]}`
        : "";
    // }
  }
  return result.substring(1, result.length);
}

export function changeUrlQuery(query) {
  const currentUrl = `${window.location.origin}${window.location.pathname}`;
  const newUrl = `${currentUrl}?${buildFilterString(query)}`;
  history.replaceState(null, "", newUrl);
}

export function objectFromQueryString() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const paramsObject = {};
  params.forEach((value, key) => {
    paramsObject[key] = value;
  });
  return paramsObject;
}
export function returnObjectsForHeader(ordering) {
  const values = ordering.split(",");
  const result = values.map((value) => {
    const sortDirections = value.startsWith("-") ? "desc" : "asc";
    const actualValue = value.replace("-", "");
    return {
      value: actualValue,
      sortDirections: sortDirections,
    };
  });
  return result;
}

export function saveFile(data, filename, type) {
  console.log(filename);
  let url = window.URL.createObjectURL(new Blob([data], { type: type }));
  let link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
