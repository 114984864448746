import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
import Notifications from "vue-notification";
Vue.use(Notifications);

import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

import ConfirmDialog from "@/components/modules/ConfirmChanges"; 

Vue.config.productionTip = false;

// Зареєструйте компонент ConfirmDialog глобально
Vue.component("confirm-dialog", ConfirmDialog);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
